body {
  background-color: #000;
  color: #ddd;
}

textarea,
input,
label,
svg {
  color: #ddd !important;
}

.border-none {
  border: none;
}

.btn-close {
  color: white;
  background-color: white;
}

.App {
  text-align: center;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #ddd;
  font-size: 10pt;
  font-weight: 300;
  line-height: 1.38;
  vertical-align: baseline;
}

.bg-lighter {
  background: #fef5e5;
}

.bg-lighter:hover {
  background: #edcf999c;
}

.bg-lighter:focus-visible {
  background-color: #fef5e5;
}

button.btn-bg-light:hover {
  background-color: #fef5e5;
}



.font-weight-lighter {
  font-weight: 300 !important;
}


.container,
.container-sm {
  max-width: 690px !important;
}

.modal-dialog {
  max-width: 690px !important;
}

.carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.2) !important;
  top: 100px !important;
  bottom: 100px !important;
}

p {
  color: #ddd;
  font-size: 10pt;
  font-weight: 300;
  line-height: 1.38;
  vertical-align: baseline;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.help {
  background-color: #fbb957;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: white;
  display: inline-block;
  cursor: pointer;
}

.tooltip-inner {
  padding: 20px !important;
  max-width: 300px !important;
  width: 300px;
  background: linear-gradient(#f5f5f5, #ebebeb) !important;
}

.tooltip-inner small,
.tooltip-inner li {
  display: block;
  text-align: left;
  margin-bottom: 0;
  color: #333;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #ebebeb !important;
}

.tooltip-inner p {
  text-align: left;
  margin-bottom: 0;
}

.container,
.container-sm {
  max-width: 690px !important;
}

.modal-dialog {
  max-width: 690px !important;
}